import { graphql } from "gatsby";
import React, { useEffect, useRef } from "react";
import { strToTitleCase } from "../functions";
import styled, { css } from "styled-components";
import { useRenderChart } from "../components/charts/chart";
import SEO from "../components/seo";
import { device } from "../components/breakpoint";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import BlockCTACard from "../components/blocks/BlockCTACard";

const ITEMS = {
  [`My Texas House by Orian, 7'9" x 10'10", Natural`]: (
    <>
      <a
        href="https://www.amazon.com/dp/B07R625K89?geniuslink=true&linkCode=li2&tag=&linkId=a98c17e0bd1ef2e36b731e01533d41e9&language=en_US&ref_=as_li_ss_il"
        target="_blank"
      >
        <img
          border={0}
          src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07R625K89&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=&language=en_US"
        />
      </a>
      <img
        src="https://ir-na.amazon-adsystem.com/e/ir?t=&language=en_US&l=li2&o=1&a=B07R625K89"
        width={1}
        height={1}
        border={0}
        alt=""
        style={{ border: "none !important", margin: "0px !important" }}
      />
    </>
  ),
  "Safavieh Home Collection Willow Clear Coffee Table": (
    <>
      <a
        href="https://www.amazon.com/dp/B00UAA1YQO?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=3d80852332a627545240546db38e5664&language=en_US&ref_=as_li_ss_il"
        target="_blank"
      >
        <img
          border={0}
          src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00UAA1YQO&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
        />
      </a>
      <img
        src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B00UAA1YQO"
        width={1}
        height={1}
        border={0}
        alt=""
        style={{ border: "none !important", margin: "0px !important" }}
      />
    </>
  ),

  [`Lifestyle Solutions HRFKS3BK Grayson Sofa, 78.7" W x 31.5" D x 32.7" H, Black`]:
    (
      <>
        <a
          href="https://www.amazon.com/Lifestyle-Solutions-HRFKS3BK-Grayson-Black/dp/B07KN1RPBB?crid=1X1DKZ1I12HZE&keywords=78%22%2BModern%2BSofa%2BLeath-aire%2BUpholstered%2BSofa%2Bwith%2BStorage%2B3&qid=1656810452&s=home-garden&sprefix=78%2Bmodern%2Bsofa%2Bleath-aire%2Bupholstered%2Bsofa%2Bwith%2Bstorage%2B3%2Cgarden%2C118&sr=1-1&th=1&linkCode=li2&tag=rentalguideai-20&linkId=8e7ff16338be6815624d4d16ca1f630e&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07KN1RPBB&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B07KN1RPBB"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`SAMSUNG 55-Inch Class Crystal 4K UHD AU8000 Series HDR Smart TV with Alexa Built-in, 3 HDMI Ports, Motion Xcelerator, Tap View, PC on TV, Q Symphony (UN55AU8000FXZA, 2021 Model)`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B08Z21BBWK?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=8f0cf41b04ecc98bb19cec0241d0f98c&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08Z21BBWK&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B08Z21BBWK"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),

  [`USX MOUNT UL Listed Full Motion TV Wall Mount for Most 37-75 inch TV, Swivel and Tilt TV Mount with Dual Articulating Arms, Wall Mount TV Bracket Up to 132lbs, VESA 600x400mm, 16" Wood Studs, XML019`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B08M92P11W?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=4fe391e1e67f3f1a3e5268df24512226&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08M92P11W&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B08M92P11W"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`Tangkula Tempered Glass End Table, Clear Sofa End Tables with Waterfall Edges & Non-Slip Pad, 18 x 18 x 16 inches, No Assembly Needed, Transparent Glass Side Table for Living Room Bedroom Office`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B091TD9S7Z?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=1ee5910f2ab65a8c61e5c0ffb9a919a2&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B091TD9S7Z&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B091TD9S7Z"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`Signature Design by Ashley Cristela Glitter Gallery Wrapped Canvas 35 x 35 inches , Gold`]:
    (
      <>
        <a
          href="https://www.amazon.com/Signature-Design-Ashley-A8000148-Cristela/dp/B07CVRKQTS?pd_rd_i=B07CVRKQTS&psc=1&linkCode=li2&tag=rentalguideai-20&linkId=7d3a612b372fa5c870edb9a61096b34f&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07CVRKQTS&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B07CVRKQTS"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`Touch Control Table Lamp Bedside Minimalist Desk Lamp Modern Accent Lamp Dimmable Touch Light with Cylinder Lamp Shade Night Light Nightstand Lamp for Bedroom Living Room Kitchen, E26 Bulb Included`]:
    (
      <>
        <a
          href="https://www.amazon.com/Minimalist-Dimmable-Cylinder-Nightstand-Included/dp/B077VNRQD2?crid=XI7OT37VICV4&keywords=modern+minimalist+light&qid=1656811273&s=home-garden&sprefix=modern+minimalist+ligh%2Cgarden%2C119&sr=1-10&linkCode=li2&tag=rentalguideai-20&linkId=e38c770a91779a24573c54e5d8a2c8e4&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B077VNRQD2&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B077VNRQD2"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`Walker Edison Rohde Contemporary 4 Cubby TV Stand for TVs up to 65 Inches, 58 Inch, Grey`]:
    (
      <>
        <a
          href="https://www.amazon.com/Walker-Edison-Rohde-Contemporary-Inches/dp/B07125993L?crid=1MCP3P0M01SDN&keywords=minimalist+grey+tv+stand&qid=1656811324&sprefix=minimalist+grey+tv+stan%2Caps%2C150&sr=8-4&linkCode=li2&tag=rentalguideai-20&linkId=3e53dbf7ca7c5594866d1befc42911a2&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07125993L&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B07125993L"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`Zak Designs Skinny Gallery Melamine Serving Tray, Easy to Hold and Modular Design Fits in Larger Tray, Perfect for Indoor/Outdoor Activities (13in x 7.5in x 1in, White, BPA-Free)`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B00885MBC4?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=86a67ac7f100551f2503d20f20e85f5e&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00885MBC4&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B00885MBC4"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
};

const HOUSE_ITEMS = {
  [`Bodum Pour Over Coffee Maker with Permanent Filter, 1 Liter, 34 Ounce, Black Band`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B00LOCYKIQ?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=d7be66309d5eedf943a9e6bae42b114c&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00LOCYKIQ&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B00LOCYKIQ"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`Zeppoli Electric Kettle - Glass Tea Kettle & Hot Water Boiler/Heater-Auto Shutoff (1.7L) & Boil-Dry Protection-Electric Tea Kettle Cordless & Portable with LED Indicator-Stainless Steel Lid & Bottom`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B078JSH1KB?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=5f73c6022aa06508674291085338a6ad&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B078JSH1KB&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B078JSH1KB"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`New Home Hero 17 pcs Kitchen Knife Set - 7 Stainless Steel Knives, 6 Serrated Steak Knives, Scissors, Peeler & Knife Sharpener with Acrylic Stand (Black, Stainless Steel)`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B075MD55N1?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=8cf1f0cfb1a1611aae6baa46f0bf8292&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B075MD55N1&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B075MD55N1"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),

  [`Brita Standard Everyday Water Filter Pitcher, White, Large 10 Cup, 1 Count`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B01FXN3E74?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=0c1a2efdb4dff11e0a048755ee2e0fb0&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01FXN3E74&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B01FXN3E74"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`Mr. Coffee 4-Cup Switch Coffee Maker, White - TF4-RB`]: (
    <>
      <a
        href="https://www.amazon.com/dp/B000FTNEGS?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=eb05f063715250d6678de09f6840778f&language=en_US&ref_=as_li_ss_il"
        target="_blank"
      >
        <img
          border={0}
          src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B000FTNEGS&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
        />
      </a>
      <img
        src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B000FTNEGS"
        width={1}
        height={1}
        border={0}
        alt=""
        style={{ border: "none !important", margin: "0px !important" }}
      />
    </>
  ),
  [`Insignia 70-inch Class F30 Series LED 4K UHD Smart Fire TV (NS-70DF710NA21, 2020 Model)`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B08CVV2Z32?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=8536b44b1f618e6bc8dbe269f95ced10&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08CVV2Z32&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B08CVV2Z32"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`HOFISH 10 Inches Medium-Feel Gel-Infused Memory Foam Mattress Full Size`]: (
    <>
      <a
        href="https://www.amazon.com/dp/B07SW2YWSH?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=9b6569ecaef4d10c7b30046dc1547029&language=en_US&ref_=as_li_ss_il"
        target="_blank"
      >
        <img
          border={0}
          src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07SW2YWSH&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
        />
      </a>
      <img
        src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B07SW2YWSH"
        width={1}
        height={1}
        border={0}
        alt=""
        style={{ border: "none !important", margin: "0px !important" }}
      />
    </>
  ),

  [`Beckham Hotel Collection Bed Pillows for Sleeping - Queen Size, Set of 2 - Cooling, Luxury Gel Pillow for Back, Stomach or Side Sleepers`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B01LYNW421?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=b104ad2fcf08aa2cfd2a047a4cc56432&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01LYNW421&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B01LYNW421"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`Beckham Hotel Collection Bed Pillows for Sleeping - Queen Size, Set of 2 - Cooling, Luxury Gel Pillow for Back, Stomach or Side Sleepers`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B01LYNW421?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=19b0e114756417f1584166f9207d8e59&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01LYNW421&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B01LYNW421"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),

  [`Echo (4th Gen) | With premium sound, smart home hub, and Alexa | Charcoal`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B07XKF5RM3?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=fce76fe29806c1bf41a087957ddcf0df&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07XKF5RM3&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B07XKF5RM3"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`Amazon Basics Vanity Mirror with Heavy Base - 1X/5X Magnification, Chrome`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B07T1Q8ZMJ?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=98c7018645dc6e30f7e0ea7217ab6954&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07T1Q8ZMJ&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B07T1Q8ZMJ"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),

  [`HSI Professional Glider | Ceramic Tourmaline Ionic Flat Iron Hair Straightener | Straightens`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B001MA0QY2?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=c6684a72736cd8c78fc0ec9b3a1f5520&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B001MA0QY2&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B001MA0QY2"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`Aroma Housewares ARC-5200SB 2O2O Model Rice & Grain Cooker, Sauté, Slow Cook, Steam, Stew, Oatmeal, Risotto, Soup, 20 Cup 10 Cup uncooked, Stainless Steel`]:
    (
      <>
        <a
          href="https://www.amazon.com/dp/B0848NBLN9?geniuslink=true&linkCode=li2&tag=rentalguideai-20&linkId=5f96f1010a862dcc4879cb4fc7f7e059&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0848NBLN9&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B0848NBLN9"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
  [`CROSOFMI Artificial Monstera Deliciosa Plant 37" Fake Tropical Palm Tree, Perfect Faux Swiss Cheese Plants in Pot for Indoor Outdoor House Home Office Garden Modern Decoration Housewarming Gift,1 Pack`]:
    (
      <>
        <a
          href="https://www.amazon.com/CROSOFMI-Artificial-Decoration-Housewarming-Gift-1Pack/dp/B081MTM28W?keywords=artificial+plant&qid=1656812410&sr=8-1&linkCode=li2&tag=rentalguideai-20&linkId=25df728d9218f4905c8cbd9bd315c81d&language=en_US&ref_=as_li_ss_il"
          target="_blank"
        >
          <img
            border={0}
            src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B081MTM28W&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=rentalguideai-20&language=en_US"
          />
        </a>
        <img
          src="https://ir-na.amazon-adsystem.com/e/ir?t=rentalguideai-20&language=en_US&l=li2&o=1&a=B081MTM28W"
          width={1}
          height={1}
          border={0}
          alt=""
          style={{ border: "none !important", margin: "0px !important" }}
        />
      </>
    ),
};

const STREAMING = {
  [`Disney+`]: <a href="https://amzn.to/3ukf9Bp" target="_blank"></a>,
  [`Prime Video`]: <a href="https://amzn.to/3ug6zUb" target="_blank"></a>,
};
const Container = styled.div`
  margin: 0 auto;
  max-width: 800px;
  ${device.sm`
    margin-top: 55px;
  `}
  a {
    text-decoration: underline;
    text-decoration-color: #000;
  }
`;
const AirbnbOptimization = ({ data, pageContext }) => {
  const { shortTermRentalSetAverages } = data;
  const { name, shortTermRentalGroup } = shortTermRentalSetAverages;
  const { charts } = shortTermRentalGroup;
  const { price_rate } = shortTermRentalGroup;
  const cityTitle = strToTitleCase(name);

  useEffect(() => {
    const boxes = gsap.utils.toArray(".interior-design");

    gsap.set(boxes, { autoAlpha: 0, y: 50 });

    gsap.set("#interior-design-container", { y: 50 });
    gsap.to(`#interior-design-container`, {
      duration: 1,
      autoAlpha: 1,
      y: 0,
    });
    boxes.forEach((box, i) => {
      // Set up your animation
      const anim = gsap.to(box, {
        duration: 1,
        autoAlpha: 1,
        y: 0,
        paused: true,
      });

      // Use callbacks to control the state of the animation
      ScrollTrigger.create({
        trigger: box,
        end: "bottom bottom",
        once: true,
        onEnter: (self) => {
          // If it's scrolled past, set the state
          // If it's scrolled to, play it
          self.progress === 1 ? anim.progress(1) : anim.play();
        },
      });
    });
  }, []);
  return (
    <div
      className="pb-40 interior-design-container"
      id="interior-design-container"
    >
      <SEO
        title={`Interior Design Guide for Airbnb ${cityTitle}`}
        description={` ${cityTitle} Interior Design for Airbnb. Settings and setup including home supplies, furniture, and must have items. Always up to date.`}
      />

      <a
        target="_blank"
        href={`https://buy.stripe.com/6oE3ec2KJ9NldFe289`}
        className="md:hidden"
      >
        <div
          className="z-10 fixed bottom-0 w-full grid items-center  justify-center text-white bg-black  p-4 
        hover:bg-white  hover:text-black
        "
        >
          👇 Optimize Your Airbnb Listing
          <br />
          <span className="pt-2">👉 Book A Consult Now! </span>
        </div>
      </a>

      <Container className="">
        <div className="pr-4 pl-4  md:p-0">
          <div className="z-10 fixed left-0 top-20 hidden md:block">
            <a
              href={"https://buy.stripe.com/6oE3ec2KJ9NldFe289"}
              target={"_blank"}
            >
              👇 Optimize Airbnb Listing
            </a>
          </div>

          <div className="col-span-8 ">
            <h1 className="text-3xl font-bold text-center">
              Airbnb Interior Design Guide {cityTitle}
            </h1>

            <h2 className="text-xl font-bold text-center mt-8">Interior</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-8">
              {Object.keys(ITEMS).map((key) => (
                <AmznItem title={key}>{ITEMS[key]}</AmznItem>
              ))}
            </div>

            <h2 className="text-xl font-bold text-center mt-8">
              Home Products
            </h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-8">
              {Object.keys(HOUSE_ITEMS).map((key) => (
                <AmznItem title={key}>{HOUSE_ITEMS[key]}</AmznItem>
              ))}
            </div>

            <h2 className="text-xl font-bold text-center mt-8">Streaming</h2>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-8">
              {Object.keys(STREAMING).map((key) => (
                <AmznItem title={key}>{STREAMING[key]}</AmznItem>
              ))}
            </div>
          </div>
        </div>
        <BlockCTACard />
      </Container>
    </div>
  );
};

const AmznItem = ({ children, title }) => {
  const ref = useRef();
  useEffect(() => {
    const a = ref.current.querySelector("a");
    const div = document.createElement("div");
    div.innerHTML = title;
    a.appendChild(div);
  });
  return (
    <div className="interior-design" ref={ref}>
      {children}
    </div>
  );
};
const ChartItem = ({ chart, city }) => {
  const ref = useRef();
  useRenderChart(chart, ref, city);
  return <div ref={ref} />;
};

export default AirbnbOptimization;

export const pageQuery = graphql`
  query ($cityName: String!) {
    shortTermRentalSetAverages(name: { eq: $cityName }) {
      shortTermRentalGroup {
        beds
        bathrooms
        access
        additional_house_rules
        amenities
        weekly_price_factor
        url
        transit
        total_price
        star_rating
        satisfaction_guest
        room_type
        room_and_property_type
        review_score
        review_count
        response_time
        response_rate
        rating_value
        rating_location
        rating_communication
        rating_cleanliness
        rating_checkin
        rating_accuracy
        price_rate_type
        price_rate
        photos
        person_capacity
        notes
        neighborhood_overview
        name
        monthly_price_factor
        longitude
        interaction
        latitude
        description
        city
        charts
      }
      name
    }
  }
`;
